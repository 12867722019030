/* eslint-disable react/require-default-props */
import { BFS_ClickEvent } from '@/common/types/override.types';
import BUTTON from '@/components/Button/Button.component';
import ContainerStyles from '@/components/container/Container.styles';
import { Heading, Flex, Box, chakra } from '@chakra-ui/react';
import { navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import * as React from 'react';

function PAGE_CONTENT_CONTAINER({
  title,
  content,
  ctaPath,
  ctaLabel,
  image,
  imageLeftAligned,
  showCta,
  imageAlt,
}: Queries.ContentfulContentBlock): JSX.Element {
  const ChakraGatsbyImage = chakra(GatsbyImage);

  return (
    <Box>
      <Heading mb="12px">{title}</Heading>
      <Flex
        direction={{
          base: 'column-reverse',
          md: imageLeftAligned ? 'row-reverse' : 'row',
        }}
      >
        <Box
          width={['100%', '60%']}
          marginRight={{
            base: 'inherit',
            md: !imageLeftAligned ? '32px' : 'inherit',
          }}
          marginLeft={{
            base: 'inherit',
            md: imageLeftAligned ? '32px' : 'inherit',
          }}
        >
          <ContainerStyles.ContentContainer>
            {content?.raw && renderRichText(content as any)}
          </ContainerStyles.ContentContainer>
          <BUTTON
            display={showCta ? 'inline-flex' : 'none'}
            margin="8px 0"
            onClick={(event: BFS_ClickEvent) => {
              event.preventDefault();
              navigate(ctaPath);
            }}
          >
            {ctaLabel || 'Learn More'}
          </BUTTON>
        </Box>
        <ContainerStyles.ContentImageContainer
          marginBottom={{
            base: '16px',
            md: 'inherit',
          }}
        >
          <ChakraGatsbyImage
            alt={imageAlt}
            image={image.gatsbyImage}
            maxHeight="540px"
          />
        </ContainerStyles.ContentImageContainer>
      </Flex>
    </Box>
  );
}

export default PAGE_CONTENT_CONTAINER;
