import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import {
  Box,
  chakra,
  Container,
  Flex,
  Heading,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';
import Tags from '@/components/tags';
import { IArticlePreviewProps } from '@/components/list/ArticlePreview.types';

function ARTICLE_PREVIEW({ posts }: IArticlePreviewProps): JSX.Element {
  if (!posts) return null;
  if (!Array.isArray(posts)) return null;

  const ChakraLink = chakra(Link);

  return (
    <Container
      maxW="container.xl"
      paddingInlineStart={[4, 8]}
      paddingInlineEnd={[4, 8]}
      paddingTop="32px"
      paddingBottom="32px"
    >
      <Box width="100%">
        <Heading marginBottom={3}>Latest Articles</Heading>
        <List
          margin={0}
          padding={0}
          listStyleType="none"
          display="grid"
          gridTemplateColumns="repeat(auto-fit, minmax(280px, 1fr))"
          gridGap={8}
        >
          {posts.map((post) => (
            <ListItem key={post.title}>
              <ChakraLink to={`/blog/${post.slug}`} textDecoration="none">
                <GatsbyImage alt="" image={post.heroImage.gatsbyImage} />
                <Heading
                  as="h3"
                  size="md"
                  margin="8px 0"
                  display="inline-block"
                >
                  {post.title}
                </Heading>
              </ChakraLink>
              <Box margin="0 0 12px" noOfLines={[2, 2, 3]}>
                {post.description?.raw &&
                  renderRichText(post.description as any)}
              </Box>
              <Flex justifyContent="right">
                <Box textAlign="right">
                  <Text mb="8px" fontWeight="bold" color="brand.gray">
                    {post.publishDate}
                  </Text>
                  <Tags tags={post.tags} />
                </Box>
              </Flex>
            </ListItem>
          ))}
        </List>
      </Box>
    </Container>
  );
}

export default ARTICLE_PREVIEW;
